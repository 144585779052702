.novel-pdf {
  display: flex;
  height: calc(100vh - var(--core-height));
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  background-color: #4a4a4a;
  position: relative;
  .mobile-rate-button{
    display: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 99;
    border-radius: 2rem;
    background: #f9ab1034;
    border: solid 1px var(--primary-color);
  }
  .navigater {
    width: 15%;
    height: calc(100vh - var(--core-height));
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    border-right: solid 1px var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    min-height: calc(100vh - var(--core-height));
    overflow-y: auto;
    overflow: auto;
    padding-top: 2rem;
    &::-webkit-scrollbar {
      display: none;
    }
    button{
        border-radius: 1rem;
        width: 80%;
        font-size: var(--smaller-font-size);
    }
    .novel-pdf-title{
        color: #fff;
        text-align: center;
        max-width: 80%;
        .title{
            color: var(--primary-color);
        }
    }
    .thumbnail {
        cursor: pointer;
        position: relative;
        .overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent;
            z-index: 100;
            border-radius: 1rem;
            &:hover{
                background: #f9ab104b;
            }
        }
        .react-pdf__Document {
            .react-pdf__Page {
              canvas {
                border-radius: 1rem;
              }
            }
          }
      .page-number {
        text-align: center;
        color: #fff;
      }
    }

    @media (max-width:768px) {
      display: none;
    }
  }
  .pdf-controls{
    display: none;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    background: #f9ab10c0;
    width: 90%;
    justify-content: space-between;
    border-radius: 1rem;
    button{
      background: transparent;
      &:hover{
        color: black;
      }
    }
  }
  .pdf-container {
    // width: 60%; /* Set the desired width of the PDF */
    margin: 0 auto; /* Center the PDF horizontally */
    height: calc(100vh - var(--top-header-height));
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #dbdbdb;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    .react-pdf__Document {
      max-width: 100% !important;
      .react-pdf__Page {
        max-width: 100% !important;
        canvas {
          max-width: 100% !important;
        }
      }
    }
    @media (max-width:768px) {
      .react-pdf__Document {
        max-width: 100vw !important;
        .react-pdf__Page {
          max-width: 100vw !important;
          canvas {
            max-width: 100vw !important;
          }
        }
      }
    }
  }
  @media (max-width:768px) {
    .mobile-rate-button{
      display: block;
    }
    .pdf-controls{
      // display: block;
      display: flex;
    }
  }
  
}
