.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
  &.ar{
    direction: rtl;
  }
  .popup {
    width: 25rem;
    height: 30rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #171717;
    border-radius: 0.5rem;
    border: solid 1px var(--primary-color);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    // gap: 2rem;
    &__dismiss {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }

    &__heading {
      text-align: center;
      text-transform: uppercase;
      padding-top: 3rem;
      margin-bottom: 2rem;
      
      h2 {
          font-size: var(--h2-font-size);
          font-weight: 800;
          color: #fff;
      }
    }

    &__body {

      width: 20rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .email,.password{
        width: 100%;
        height: 2rem;
        background: #404040;
        border-radius: 2rem;
        border: solid 1px var(--primary-color);
        input{
            width: 100%;
            height: 100%;
            background: none;
            padding: 0 1rem;
            color: #fff;
            &::placeholder{
                color: #fff;
            }
        }
      }
      .password{
        position: relative;
        cursor: pointer;
        .icon{
            position: absolute;
            top: 50%; right: 1rem;
            transform: translateY(-50%);
        }
      }
      .forgot-password{
        width: 100%;
        text-align: end;
        a{
            color: #fff;
            text-decoration: none;
        }
      }

      .submit{
        width: 100%;
        border-radius: 2rem;
        font-size: var(--normal-font-size);
        &:hover{
            background: #404040;
        }
      }
      .register-link{
        color: #fff;
        a{
            color: #fff;
            text-decoration: underline;
        }
      }

      .error{
        color: red;
        font-size: var(--small-font-size);
      }
    }
    @media (max-width:768px) {
      max-width: 25rem;
      width: 90%;
    }
  }
}
.ar{
  .icon{
    right: unset !important;
    left: 1rem;
}
}