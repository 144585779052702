.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--core-height));
  overflow: hidden;
  background: #171717;
  .spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid var(--primary-color);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.dashboard-body{
    .loader{
        height: calc(100vh - var(--top-header-height));
    }
}
