.footer{
    width: 100%;
    height: 5rem;
    background: var(--dark-color);
    display: grid;
    grid-template-columns: repeat(3,30%);
    align-items: center;
    justify-content: center;
    *{
        text-align: center;
        color: #fff;
    }
    a{
        text-decoration: underline;
    }
    @media (max-width:768px) {
        height: 10rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}