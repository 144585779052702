.privacy {
  .cover {
    min-height: calc(100vh - var(--core-height));
    overflow-y: auto;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
    }
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .title{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: var(--h1-font-size);
        color: #fff;
        z-index: 10;
        text-transform: uppercase;
    }
  }
  .content{
    background: #171717;
    padding: 4rem;
    @media (max-width:768px) {
        padding: 1rem;
    }
  }
}
