.novel {
  overflow-y:hidden;
  background: #171717;
  min-height: calc(100vh - var(--core-height));
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5rem;
    padding-left: 5rem;
    padding-top: 2rem;
    height: fit-content;
    padding-bottom: 50px;

    &__images {
      .main-container {
        margin-top: 50px;
        background-color: #fff;
        width: 18rem;
        height: 20rem;
        border-radius: .5rem;
        position: relative;
        margin-bottom: .5rem;
        img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform 0.3s ease;
            &:hover{
                transform: translate(-50%, -50%) scale(1.5);
            }
        }
      }
      .pagination{
        margin-top:30px;
        width: 18rem;
        display: grid;
        grid-template-columns: repeat(4,4rem);
        gap: .5rem;
        justify-content: center;
        .card{
            margin-top: 35px;
            background: #fff;
            height: 6rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            max-height: 98%;
            border-radius: .5rem;
            cursor: pointer;
            &:hover{
                scale: 105%;
                border: solid 1px var(--primary-color);
            }
        }
        .active{
            border: solid 1px var(--primary-color); 
            border-radius: .5rem;
        }
      }
    }

    &__content{
        width: 25rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        // &:nth-child(2){
        //     gap: .5rem;
        // }

        .title{
            color: var(--primary-color);
            text-transform: capitalize;
            font-size: var(--h1-font-size);
        }

        .author{
            color: #fff;
            text-transform: capitalize;
            font-size: var(--h3-font-size);
        }
        .rating{
            display: flex;
            gap: 1rem;
            .stars{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: .2rem;
            }
            .count{
                color: #fff;
                font-size: var(--small-font-size);
            }
        }

        .description{
            color: #fff;
            width: 40vw;
        }

        .request{
            width: 90%;
        }
        .show{
            text-decoration: none;
            color: var(--primary-color);
        }
    }

    &__wish{
        background: transparent;
        border: solid .1rem #fff;
        border-radius: 2rem;
        width: 12rem;
        color: #fff;
        position: relative;
        height: 3rem;
        transition: none !important;
        &:hover{
            border: solid .1rem var(--primary-color);
            color: var(--primary-color);
            transition: none;

            svg{
                fill: var(--primary-color);
            }
        }
        svg{
            height: 2rem;
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            fill: #fff;
        }
        &.active{
            border: solid .1rem var(--primary-color);
            color: var(--primary-color);
            transition: none;

            svg{
                fill: var(--primary-color);
            }
        }
    } 
    @media (max-width:1100px) {
        .description{
            color: #fff;
            width: fit-content;
        }
        overflow: hidden;
        padding: 2rem 0 2rem 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        gap: 2rem;
        &__content{
            padding: 1rem;
            border: solid 1px var(--primary-color);
            width: 80vw;
            align-items: center;
        }
    }  

  }

  .reviews{
    height: auto;
    width: 100%;
    .reviews-heading{
        display: flex;
        color: #fff;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 3rem 6rem;
        color: #fff;
        &.ar{
            direction: rtl;
        }
        h1{
            font-size: var(--h1-font-size);
        }
        h3{
            font-size: var(--h3-font-size) ;
        }
    }
    .reviews-body{
        padding: 3rem 12rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .review{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .review-header{
                color: #fff;
                display: flex;
                position: relative;

                .user-icon{
                    position: absolute;
                    width: 3rem;
                    height: 3rem;
                    left: 0;
                    top: 0; 
                    transform: translate(-130% , -15%);
                    // left: -50%;
                }

                h3{
                    width: 12rem;
                    font-size: var(--h3-font-size);
                }

                .stars{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: .2rem;
                }
            }

            .review-body{
                color: #fff;
            }
        }
    }

    @media (max-width:768px) {
        height: fit-content;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .reviews-heading{
            padding: 0 1rem;
        }
        .reviews-body{
            padding: 2rem 1rem;
            .user-icon{
                display: none;
            }
            .review-header{
                width: 100%;
                justify-content: space-between;
            }
            .review-body{
                width: 100%;
                font-size: var(--small-font-size);
            }
        }
    }
  }
}


