.request-novel{
    // h1{
    //     font-size: var(--h2-font-size);
    // }
    // h2{
    //     font-size: var(--h3-font-size);
    // }
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 10;
    &.ar{
        direction: rtl;
    }
    .card{
        position: absolute;
        top: 0; right: 0;
        width: 40%;
         height: 100%;
        background: #171717;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
        &__header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            .dismiss-icon{
                cursor: pointer;
                width: 1rem;
                height: 1rem;
            }
        }

        &__informations{
            display: flex;
            gap: 1rem;
            .image-background{
                height: 4rem;
                width: 3.6rem;
                background: #ffff;
                border-radius: .5rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                img{
                    width: 70%;
                    max-height: 95%;
                    border-radius: .5rem;
                    object-fit: cover;
                }
            }
            .titles{
                height: 4rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h2{
                    color: var(--primary-color);
                }
                p{
                    color: #fff;
                }
            }
        }

        &__redeem{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .redeem-header{
                color: #fff;
            }

            .redeem-apply{
                height: 2.5rem;
                position: relative;
                background: #404040;
                border-radius: 2rem;
                input{
                    background: none;
                    height: 100%;
                    width: 100%;
                    padding-left: 2rem;
                    color: #fff;
                    font-size: var(--h4-font-size);
                }
                .apply-button{
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    border-radius: 2rem;
                    font-size: var(--h4-font-size);
                }
            }
        }

        &__balance{
            .total,.subtotal,.discount{
                display: flex;
                justify-content: space-between;
                // padding-inline: 1rem;
                color: #fff;
            }
            // .total,.discount{
            //     display: none;
            // }
            // .show{
            //     display: flex;
            // }
        }

        &__payment{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            h2{
                color: #fff;
            }
            .number-card{
                width: 100%;
                height: 2.5rem;
                background: #404040;
                border-radius: 2rem;
                input{
                    background: none;
                    height: 100%;
                    width: 100%;
                    padding-left: 2rem;
                    color: #fff;
                    font-size: var(--h4-font-size);
                }
            }
            .info-card{
                display: flex;
                justify-content: space-between;
                .exp,.cvv{
                    height: 2.5rem;
                    width: 48%;
                    background: #404040;
                    border-radius: 2rem;
                    input{
                        background: none;
                        height: 100%;
                        width: 100%;
                        padding-left: 2rem;
                        color: #fff;
                        font-size: var(--h4-font-size);
                    }
                }
            }
        }

        &__notice{
            color: #fff;
            font-size: var(--smaller-font-size);
        }

        &__confirm{
            height: 2.5rem;
            font-size: var(--h4-font-size);
            border-radius: .5rem;
        }
        .error{
            border: solid .1px red;
        }

        @media (max-width:768px) {
            width: 100vw;
            opacity: 1;
            transition: opacity .8s ease-in-out;
            &.fade{
                opacity: 0;
                transition: opacity .8s ease-in-out;
            }
        }
    }
}

.ar{
    .redeem-apply{
        input{
            padding-left: unset;
            padding-right: 2rem;
        }
    }
    .apply-button{
        position: absolute;
        top: 0;
        left: 0;
        right: unset !important;
    }
}