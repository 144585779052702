:root{
  font-size: 16px;
  --core-height: 160px;
}
@font-face {
  font-family: 'Kohinoor Bangla';
  src: url('./assets/fonts/KohinoorBangla-Regular.otf');
  font-weight: normal;
  font-size: normal;
}
body {
  margin: 0;
  font-family: 'Kohinoor Bangla',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button:disabled {
  cursor: not-allowed;
}
.mx-auto {
  margin: 0 auto;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.gap-4 {
  gap: 1rem;
}
.w-4 {
  width: 16px;
}
.h-4 {
  width: 16px;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #F9AA10;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 30px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 120px;
  z-index: 100;
}

.dropdown-item {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
}

.dropdown-item:hover {
  background: #f0f0f0;
}
.show-small {
  display: flex;
}
.flex-big {
  display: none;
}
@media (min-width: 1280px) {
  .show-small {
    display: none;
  }
  .flex-big {
    display: flex;
  }
}
.min-h {
  min-height: calc(100vh - 208px);
}