.reset-password {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
  &.ar{
    direction: rtl;
  }
  .popup{
    width: 50%;
    height: 30rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #171717;
    border-radius: 0.5rem;
    border: solid 1px var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-container {
      width: 90%;
      display: flex;
      gap: 4rem;
      flex-direction: column;
      justify-content: center;
      .form-input {
        display: flex;
        background: #242424;
        border-radius: 1rem;
        border: solid 1px var(--primary-color);
        position: relative;
       height: 2rem;
        input {
          height: 100%;
          width: 100%;
          background: transparent;
          padding: 0 1rem;
          color: #fff;
          border: none;
          border-radius: inherit;
          &::placeholder {
            color: #fff;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
        .eye-icon {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
      .error{
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        color: red;
        font-size: var(--smaller-font-size);
      }
      .submit{
        height: 2rem;
      }
    }
    @media (max-width:768px) {
        width: 100%;
        height: 100%;
        border-radius: 0%;
        .form-container{
            // grid-template-columns: 100%;
            // .form-input{
            //     &:nth-child(3) {
            //         grid-column: span 1;
            //     }
            // }
            .submit{
            // grid-column: span 1;
            margin-top: 2rem;
            height: 2rem;
            }
        }
    }
  }
  .dismiss {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .heading {
    text-align: center;
    text-transform: uppercase;
    padding-top: 3rem;
    margin-bottom: 2rem;

    h2 {
      font-size: var(--h2-font-size);
      font-weight: 800;
      color: #fff;
    }
  }
  .submit {
    width: 100%;
    border-radius: 2rem;
  }
  .error {
    color: red;
  }
}
