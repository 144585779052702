.wishlist{
    padding-top: 3rem;
    background: var(--dark-color);
    min-height: calc(100vh - var(--top-header-height));
    display: grid;
    padding-bottom: 3rem;
    grid-template-columns: repeat(3,15rem);
    gap: 3rem;
    justify-content: center;
    place-items: center;
    @media (max-width: 768px) {
        grid-template-columns: repeat(1,20rem);
    }
    a {
        width: 100%;
        text-decoration: none;
        color: #171717;
      }
    .card{
        background: #F4F4F4;
        width: 100%;
        height: 16rem;
        border-radius: 1rem;
        box-shadow: var(--box-shadow);
        border: var(--border);
        position: relative;
        z-index: 1;
        &__image{
            opacity: 1;
            position: absolute;
            left: 50%; bottom: 20%;
            transform: translate(-50%);
            max-width: 8rem;
            max-height: 12.8rem;
            img{width: 100%;}
        }
        &__title{
            width: 100%;
            opacity: 1;
            position: absolute;
            left: 50%; top: 80%;
            transform: translate(-50%);
            text-transform: uppercase;
            text-align: center;
            h1{
                font-size: var(--h4-font-size);
            }
        }

        &__price{
            opacity: 1;
            position: absolute;
            left: 50%; top: 90%;
            transform: translate(-50%);
            display: flex;
            // column-gap: 1.5rem;  
            justify-content: center;
            font-size: var(--smaller-font-size);
        }

        &__description{
            opacity: 0;
            // display: none;
            position: absolute;
            top: 5rem; left: 50%;
            transform: translate(-50%);
            width: 90%;
            text-align: center;
            // transition: opacity 0.4s ease-in-out; 
        }

        &__fav{
            position: absolute;
            top: 1rem; right: 1rem;
            z-index: 2;
            svg{
                width: 2rem;
            }
            &:hover{
                cursor: pointer;
                .icon{
                    fill: var(--primary-color);
                }
            }
        }

        &:hover{
            cursor: pointer;
            .card__description{
                // display: block;
                opacity: 1;
            }
            .card__price,.card__title,.card__image{
                display: none;
                opacity: 0;
                transition: opacity 0.4s ease-in-out; 
            }
        }

        .card__price,.card__title,.card__image{
            transition: opacity 0.4s ease-in-out; 
        }

        .line-throw{
            text-decoration: line-through;
        }

        
    }
}




  .message-box {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: 50px;
    color: #FFF;
    font-family: Roboto;
    font-weight: 300;
  }
  .message-box h1 {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 40px;
  }
  .buttons-con .action-link-wrap {
    margin-top: 40px;
  }
  .buttons-con .action-link-wrap a {
    background: #F9AA10;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFF;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px
  }
  .buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
  }
  
  #Polygon-1 , #Polygon-2 , #Polygon-3 , #Polygon-4 , #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }
  #Polygon-2 {
    animation-delay: .2s; 
  }
  #Polygon-3 {
    animation-delay: .4s; 
  }
  #Polygon-4 {
    animation-delay: .6s; 
  }
  #Polygon-5 {
    animation-delay: .8s; 
  }
  
  @keyframes float {
      100% {
      transform: translateY(20px);
    }
  }
  @media (max-width: 740px) {
    .message-box h1 {
        font-size: 20px;
        line-height: 4px;
        margin-bottom: 46px;
      }
    .message-box {
      width: 100%;
      margin-top: -100px;
      margin-left: -170px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }
  }