/*=============== BASE ===============*/
:root {
    --header-height: 8rem;
    --secondary-header-height: 5rem;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html{
    scroll-behavior: smooth;
    // overflow-x: hidden;
}
.header-height {
    margin-top: var(--header-height);
}
.secondary-header-height {
    margin-top: var(--secondary-header-height);
}
body{
    overflow-x: hidden;
}
body.dashboard-body{
    margin: var(--top-header-height) 0 0 20%;
    @media (max-width:768px) {
        margin: var(--top-header-height) 0 0 0;
    }
    section{
        min-height: calc(100vh - var(--core-height));
        overflow-y: auto;
    }

    &.rtl{
        margin: var(--top-header-height) 20% 0 0;
    @media (max-width:768px) {
        margin: var(--top-header-height) 0 0 0;
    }
    }
}
// h1,h2,h3,h4{
//     color: var(--title-color);
//     font-weight: var(--font-semi-bold);
// }

ul{
    list-style: none;
}
a{
    text-decoration: none;
}
img{
    max-width: 100%;
    height: auto;
}
button,
input{
    border: none;
    outline: none;
}
textarea{
    outline: none;
    border: none;
}

button{
    cursor: pointer;
    display: inline-block;
    background-color: var(--primary-color);
    color: black;
    padding: .5rem 2rem;
    border-radius: .2rem;
    transition: .55s;
    &:hover{
        background-color: var(--dark-color);
        color: var(--primary-color);
    }
}
.line-throw{
    text-decoration: line-through;
}

section{
    border-top: solid 1px var(--primary-color);
}

.spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }