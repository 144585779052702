.dashboard-novels{
    padding-top: 3rem;
    background: var(--dark-color);
    min-height: calc(100vh - var(--core-height));
    display: grid;
    padding-bottom: 3rem;
    grid-template-columns: repeat(3,15rem);
    gap: 3rem;
    justify-content: center;
    place-items: center;
    @media (max-width: 768px) {
        grid-template-columns: repeat(1,20rem);
    }
    .card{
        background: #F4F4F4;
        width: 100%;
        height: 16rem;
        border-radius: 1rem;
        box-shadow: var(--box-shadow);
        border: var(--border);
        position: relative;
        z-index: 1;
        cursor: pointer;
        &:hover{
            background: var(--primary-color);
        }
        &__image{
            opacity: 1;
            position: absolute;
            left: 50%; bottom: 20%;
            transform: translate(-50%);
            max-width: 8rem;
            max-height: 12.8rem;
            img{width: 100%;}
        }
        &__title{
            width: 100%;
            opacity: 1;
            position: absolute;
            left: 50%; top: 80%;
            transform: translate(-50%);
            text-transform: uppercase;
            text-align: center;
        }

        
    }
}