.rate-novel {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  backdrop-filter: blur(5px);
  z-index: 1000;
  &.ar{
    direction: rtl;
  }
  .popup {
    width: 50%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #171717;
    border-radius: 0.5rem;
    border: solid 1px var(--primary-color);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 1rem;
    &__dismiss {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
      }
  
      &__heading {
        text-align: center;
        text-transform: uppercase;
        padding-top: 3rem;
        
        h2 {
            font-size: var(--h2-font-size);
            font-weight: 800;
            color: #fff;
        }
      }
      &__stars{
        display: flex;
        justify-content: center;
        fill: none;
        width: 80%;
        .box{
            width: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        svg{
            width: 4rem;
        }
      }

      &__notes{
        width: 80%;
        background: #404040;
        border: solid 1px #F9AA10;
        border-radius: .5rem;
        height: 40%;
        textarea{
            width: 100%;
            height: 100%;
            background-color: transparent;
            resize: none;
            padding: 1rem 1rem;
            font-weight: var(--font-bold);
            font-size: var(--h3-font-size);
            border-radius: inherit;
            color: #fff;
        }
      }
      &__submit{
        width: 80%;
        border-radius: .5rem    ;
      }
  }
  @media (max-width:768px) {
    .popup{
      width: 100%;
      height: 100%;
    }
  }
}
