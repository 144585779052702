.profile {
  min-height: calc(100vh - var(--core-height));
  overflow-y: auto;
  background: var(--dark-color);
  padding: 4rem;
  &.ar{
    direction: rtl;
  }
  .form-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    .profile-informations,
    .change-password {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: 0 auto;
    }
    .line {
      width: 100%;
      display: flex;
      gap: 1rem;
      .input-wrapper {
        background: #242424;
        width: 50%;
        height: 35px;
        border-radius: 1rem;
        border: solid 1px var(--primary-color);
        position: relative;
        input {
          height: 100%;
          width: 100%;
          background: transparent;
          padding: 0 1rem;
          color: #fff;
    font-size: 13px;          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
        .eye-icon{
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            cursor: pointer;
        }
      }
    }
    .radio-gender {
      background: #242424;
      width: 60%;
      height: 2rem;
      border-radius: 1rem;
      border: solid 1px var(--primary-color);
      display: flex;
      button {
        width: 50%;
        background: transparent;
        color: #fff;
  font-size: 13px;        &:hover {
          color: var(--primary-color);
        }
      }
      .active {
        background: var(--primary-color);
        color: black;
        &:hover {
          color: black;
        }
      }
    }
    button {
      border-radius: 1rem;
font-size: 13px;    }
    .change-password-button {
      background: #ffff;
      &:hover {
        background: var(--dark-color);
      }
    }
    .back-button {
      background: #ffff;
      width: 50%;
      &:hover {
        background: var(--dark-color);
      }
    }
    .submit-password {
      width: 50%;
    }
    .error {
      border: solid 1px red !important;
    }
    .error-message{
        color: red;
        font-size: var(--smaller-font-size);
    }
    .hide {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }
    .show {
        height: auto;
        opacity: 1;
        transition: height .8s ease-in-out, opacity .8s ease-in-out;
    }
  }
  @media (max-width:768px) {
    .form-container{
      width: 100%;
      .radio-gender{
        width: 100%;
      }
      .change-password{
        .line{
          .input-wrapper{
            width: 100%;
          }
        }
        button{
          width: 100%;
        }
      }
    }
  }
}
.ar{
  .eye-icon{
    right: unset !important;
    left: 1rem;
}
}
